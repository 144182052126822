@import './CreateAccount.scss';
@import './Login.scss';
@import './blog/index.scss';
@import './hover.scss';

// ========== Flex gap ==========
// Bootstrap provides (presumably) a 'gap-n' class, rather than being 'flex-gap-n'
// so those can probably be used instead
// Though it does seem like perhaps the version of bootstrap currently installed in
// this project doesn't have those classes
.flex-gap-1 {
  gap: 1rem !important;
}
.flex-gap-2 {
  gap: 1.5rem !important;
}

.flex-gap-10 {
  gap: 10px !important;
}

.flex-basis-0 {
  flex-basis: 0 !important;
}

.strikethrough {
  text-decoration-line: line-through !important;
  text-decoration-thickness: 10% !important;
}

.content-wrapper {
  max-width: 1860px;
  margin-left: auto;
  margin-right: auto;
}

// From General.scss in account-settings
// Should be removed once identified that things won't break
.has-icon-left .form-control-position svg {
  top: 17px;
}

.icon-position-right {
  position: absolute;
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  right: 0.4rem;
  bottom: 0.2rem;
}
