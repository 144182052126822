$xs: 576px;
$sm: 768px;

.company {
  font-size: smaller;

  position: relative;
  display: inline-block;
  // word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  max-height: 1.5em; /* (Number of lines you want visible) * 
  max-(line-height) */
  max-width: 6em;
  line-height: 1.2em;
  text-align: justify;
}
.header-navbar .navbar-container ul.nav li a.dropdown-user-link {
  padding: 1rem;
}

.noclick {
  cursor: default !important;
}

.clickable {
  cursor: pointer;
}

.feather-16 {
  height: 16px;
  width: 16px;
}

@media only screen and (max-width: $xs) {
  .shrink-btn.btn {
    padding: 1rem 0.6rem;
  }
}

// @media only screen and (max-width: $sm) {
//   .shrink-btn.btn {

//   }
// }
