.welcome-3 {
  margin: 5em 0 1em 0;
  // margin: 5em 0 0 0;
  font-weight: bold;
  color: grey;
}

.welcome-message {
  padding-left: 20%;
  padding-right: 25%;
}

.summary {
  margin: 1em 0 0 0;
  font-weight: bold;
  color: grey;
  padding-left: 1em;
}

// .privacy {
//   color: blue;
//   text-decoration: underline;
// }

h1 {
  font-weight: bold;
  margin: 0 0 10px;
}

h4 {
  margin-bottom: 30px;
}
.Payment {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.App-payment {
  padding: 30px;
}

.plan {
  width: auto;
}

.profile {
  list-style: none;
}

form {
  margin: 30px auto 0;
  // max-width: 400px;
}

.form-actions {
  margin-top: 15px;
}

.App-cards {
  margin: 0 auto;
  max-width: 1280px;
  text-align: center;
}

.App-cards-list {
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.App-cards-list > * {
  transform: scale(0.8);
  margin-bottom: 30px !important;
}

.App-highlight {
  font-family: serif;
  margin: 15px auto 0;
  max-width: 300px;
}

.App-highlight > div {
  padding-left: 40px;
}

.App-badges {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.App-badges a {
  display: block;
}

.App-badges a + a {
  margin-top: 18px;
}

.github__btn {
  align-items: center;
  background-color: #ccc;
  border-radius: 4px;
  color: #444;
  display: flex;
  line-height: 1;
  padding: 8px 12px;
}

.github__btn:hover {
  color: #444;
  text-decoration: none;
}

.github__btn img {
  display: inline-block;
  height: 28px;
  margin-right: 8px;
}

.App-credits {
  background-color: #000;
  color: #fff;
  line-height: 50px;
  text-align: center;
}

.App-credits a {
  color: #fff;
  font-weight: bold;
}

@media screen and (min-width: 600px) {
  .App-badges {
    flex-direction: row;
  }

  .App-badges a + a {
    margin-top: 0;
    margin-left: 18px;
  }
}

ul {
  list-style: none;
}

.features {
  height: 275px;
}

.features li:before {
  content: '✓';
  color: limegreen;
}

.create-account {
  border-bottom: 5px solid #007bff;
  margin-left: 10px;
}

.react-ripples {
  width: 100%;
  margin-bottom: 2em;
}

.continue-2 {
  // margin-top: 2em;
  // margin-bottom: 2em;
  overflow: hidden;
  text-align: center;
  color: gray;
}

.continue-2:before,
.continue-2:after {
  background-color: #c7c7c7;
  content: '';
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 30%;
}

.continue-2:before {
  right: 0.5em;
  margin-left: -50%;
}

.continue-2:after {
  left: 0.5em;
  margin-right: -50%;
}

.outline {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #007bff;
  border-color: #007bff;
}

.outline:focus {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.user {
  margin: 0;
  color: #5c5c5c;
  font-weight: bold;
}

.auth-2 {
  width: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20%;
  padding-right: 25%;
}

.auth-icon-2 {
  display: flex;
  // flex-direction: column;
  // justify-content: center;
  height: 50px;
}

.auth-box-2 {
  width: 50%;
  border: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
  margin-right: 30px;
  color: grey;
  border-radius: 5px;
  padding-top: 6px;
  padding-bottom: 6px;
  // height: 30px;
  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.9);
  }
}

.form-group {
  margin-bottom: 1rem;
}

.auth-box-3 {
  width: 50%;
  border: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
  color: grey;
  border-radius: 5px;
  padding-top: 6px;
  padding-bottom: 6px;
  // height: 30px;

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.9);
  }
}

#loop-logo-icon {
  height: 3em;
}

#leadbright-logo-noicon {
  height: 3em;
}

#google-icon {
  height: 70%;
}

#microsoft-icon {
  height: 70%;
}

.checkout {
  padding: 1em;
}

.checkout .planTitle {
  font-weight: 700;
  font-size: 2em;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.25);
}

.titleContainer {
  height: 120px;
}

.price {
  height: 100px;
}

.register-section {
  box-shadow: 0px 4px 25px 0px rgb(0 0 0 / 10%);
  padding-left: 0;
  padding-right: 0;
  height: 100vh;
}

.has-icon-left .form-control-position svg {
  top: 17px;
}

.textfield-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding-left: 18px;
  padding-right: 32px;
}

.form-control:focus {
  color: #4e5154;
  background-color: #fff;
  border-color: #007bff;
  outline: 0;
  box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}
.form-control:focus ~ .form-control-position svg {
  stroke: #007bff;
}

.auth-icon {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 50px;
  margin-bottom: 1em;
}

.auth-box {
  height: 100%;
  width: 6em;
  border: 1px solid #c7c7c7;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.9);
  }
}

#loop-logo-icon {
  height: 5em;
}

#leadbright-logo-noicon {
  height: 5em;
}

#google-icon {
  height: 70%;
}

#microsoft-icon {
  height: 70%;
}

@media only screen and (max-width: 767.5px) {
  .background {
    visibility: hidden;
    padding: 0;
    height: 0;
    width: 0;
  }
  .logo {
    visibility: visible;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .welcome-message {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
  .auth-icon-2 {
    flex-direction: column;
    margin-bottom: 30px;
  }
  .auth-box-2,
  .auth-box-3 {
    height: 23.5px;
    width: 200px;
    margin-right: 0;
  }
  .row {
    margin-left: 0;
  }
  .register-section {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
  .textfield-container {
    padding-left: 150px;
    padding-right: 150px;
  }
  .auth-2 {
    padding: 0;
  }
}

@media only screen and (min-width: 767.5px) and (max-width: 1150px) {
  .auth-icon-2 {
    flex-direction: column;
    margin-bottom: 30px;
  }
  .auth-box-2,
  .auth-box-3 {
    height: 23.5px;
    width: 200px;
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) {
  .logo {
    visibility: hidden;
  }
}

@media only screen and (max-width: 640px) {
  .textfield-container {
    padding-left: 10%;
    padding-right: 10%;
  }
}
