.blog-preview-card-image {
   object-fit: cover;
   height: 300px;
   border-bottom-left-radius: 0;
   border-bottom-right-radius: 0;
}

.blog-preview-card-body {
   display: flex;
   flex-direction: column;
   letter-spacing: 0;

   .blog-title {
      font-size: 1.5rem;
      line-height: 2rem;
      letter-spacing: 0;
      margin-bottom: 0;
   }

   .blog-tag-list {
      padding: 1rem 0;
   }

   // .blog-posting-content {
   //    overflow: hidden;
   //    max-height: 15rem;
   // }

   .blog-preview-card-readmore {
      color: rgb(115, 103, 240);
      font-family: inherit;
      font-size: 1.1rem;
      // TODO see if the extra 8px from wherever it's coming from can be removed
      margin-left: -8px;
   }
}