// Wrapper around the whole card on posting details page
.blog-wrapper {
   max-width: 1140px;
   margin-left: auto;
   margin-right: auto;

   // Title, seperately entered
   .blog-title {
      font-size: 3rem;
      padding: 1rem 3.5rem;
      letter-spacing: 0;
   }

   .blog-tag-list {
      padding: 0rem 3.5rem;
      letter-spacing: 0;
   }

   // Specifically the discovery content coming from database
   // Separate from the title/tags/footer
   .blog-posting-content {
      margin-top: 1.5rem;
      letter-spacing: 0rem;
      padding: 0rem 3.5rem 3.5rem 3.5rem;
      p {
         line-height: 1.9rem;
         font-size: 1.1875rem;
         color: black;
      }
      h1 {
         font-size: 3rem;
         line-height: 4rem;
         padding: 2rem 0;
         font-weight: 600;
      }
      h2 {
         font-size: 2rem;
         line-height: 2.6rem;
         padding: 1.5rem 0;
         font-weight: 600;
      }
      h3 {
         font-size: 1.3rem;
         line-height: 1.8rem;
         padding: 1.5rem 0;
         font-weight: 600;
      }
      ul {
         list-style: disc;
         font-size: 1.1875rem;
         line-height: 1.8rem;
         color: black;
         margin: 2.5rem 0;
      }
      li {
         padding: 1rem;
      }
      .badge {
         font-weight: 400;
         font-size: 1.1875;
      }
   }

   .blog-footer {
      display: flex;
      gap: 1rem;
   }
}
