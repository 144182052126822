.welcome {
  margin: 1em 0;
  font-weight: bold;
}

.user {
  margin: 0;
  color: #5c5c5c;
  font-weight: bold;
}

.auth {
  width: inherit;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 2em;
}

.has-icon-left .form-control-position svg {
  top: 17px;
}

.has-icon-left .form-control-position-right svg {
  left: 17px;
  // top: 17px;
}

.continue {
  overflow: hidden;
  text-align: center;
  margin-bottom: 2em;
  color: gray;
}

.continue:before,
.continue:after {
  background-color: #c7c7c7;
  content: '';
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.continue:before {
  right: 0.5em;
  margin-left: -50%;
}

.continue:after {
  left: 0.5em;
  margin-right: -50%;
}

// .react-ripples {
//   font-family: 'Montserrat';
//   width: 100%;
//   margin-top: 1em;
//   margin-bottom: 2em;
// }

.auth-icon {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 50px;
}

.auth-box {
  height: 100%;
  width: 6em;
  border: 1px solid #c7c7c7;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.9);
  }
}

#loop-logo-icon {
  height: 5em;
}

#leadbright-logo-noicon {
  height: 5em;
}

#google-icon {
  height: 70%;
}

#microsoft-icon {
  height: 70%;
}
